import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CalculatorItem } from '@models/calculatorItem.model';
import { OrderCardData } from '@models/orderCardData.model';
import { StatsData } from '@models/stats.model';
import { Store } from '@ngrx/store';
import { UtilsService } from '@services';
import { Constants } from '@share/constants';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';

import { AppState } from 'src/app/core/app.reducer';

@Component({
  selector: 'app-bonus-pack',
  templateUrl: './bonus-pack.component.html',
  styleUrls: ['./bonus-pack.component.scss'],
})

export class BonusPackComponent implements OnInit {

  @Input() order: OrderCardData;
  @Output() animationFinished: EventEmitter<any> = new EventEmitter();

  countNumber = 0;

  finishedStatus = '';
  finishedMessage = '';
  finishOrderTimes: any = {};



  showingBonus: boolean | null = null

  stats: StatsData;
  stats$: Observable<StatsData> = this.store.select(state => state.stats);
  statsSubscription$: Subscription;

  timeRestaurant = '';
  timeSite = '';

  timerToClose: any;
  timerToShow: any;



  constructor(
    private utilsService: UtilsService,
    private store: Store<AppState>,
  ) {
    this.statsSubscription$ = this.stats$.subscribe(stats => {
      this.stats = stats;
    });
  }

  ngOnDestroy() {

    this.statsSubscription$.unsubscribe();
    // this.closeWebCam();
  }

  ngOnInit() {
    console.log("entra en BonusPackComponent");

    this.showingBonus = true;
    this.displayFinalAnimation();
  }

  private checkOrderState() {

    const nowTime: number = this.utilsService.getTimeStamp();
    const difference: number = (this.order?.finish_time_estimated - nowTime) / 1000;

    switch (true) {
      case difference > Constants.WARNING_STATUS_TIME:
        this.finishedStatus = 'success';
        this.finishedMessage = this.utilsService.getRandomMessage('success');
        break;
      case difference > 0 && difference < Constants.WARNING_STATUS_TIME:
        this.finishedStatus = 'warning';
        this.finishedMessage = this.utilsService.getRandomMessage('warning');
        break;
      default:
        this.finishedStatus = 'alert';
        this.finishedMessage = this.utilsService.getRandomMessage('alert');
        break;
    }
  }

  closeFinishedButton() {
    this.showingBonus = false;
    const timeoutAnimation = setTimeout(() => {
      this.animationFinished.emit();
      clearTimeout(timeoutAnimation);
    }, 500);
  }


  // countUp(totalDuration) {
  //   const finishCount = 100;
  //   const interval = (totalDuration / finishCount);

  //   const countInterval = setInterval(() => {
  //     if (this.countNumber >= finishCount) {
  //       clearInterval(countInterval);
  //       return;
  //     }

  //     this.countNumber++;
  //   }, interval);
  // }

  displayFinalAnimation() {
    this.timerToShow = setTimeout(() => {
      const milliseconds: number = this.utilsService.getTimeStamp();

      this.finishOrderTimes = {
        orderTime: moment(moment(milliseconds).diff(moment(this.order.dates.timestamp_0))).format('mm:ss'),
        brandTime: moment(this.stats.firebaseStats.restaurants.find(restaurant => restaurant.internal_id === this.order.restaurant.id).prep_time_med).format('mm:ss') || '--:--',
        siteTime: moment(this.stats.firebaseStats.totals.prep_time_med).format('mm:ss') || '--:--',
      };
      this.checkOrderState();
      this.timeRestaurant = this.getTimeStatus(this.stats.firebaseStats.restaurants.find(restaurant => restaurant.internal_id === this.order.restaurant.id).prep_time_med);
      this.timeSite = this.getTimeStatus(this.stats.firebaseStats.totals.prep_time_med);
      clearTimeout(this.timerToShow);
    }, 500);

    // this.countUp(5000);

    this.timerToClose = setTimeout(() => {
      // this.showingBonus = false;
      // const timeoutAnimation = setTimeout(() => {
      //   this.animationFinished.emit();
      //   clearTimeout(timeoutAnimation);
      // }, 500);
      this.closeFinishedButton();
      clearTimeout(this.timerToClose);
    }, 5000);
  }

  getTimeStatus(time: number) {
    if (time <= 600000) {
      return 'success';
    } else if (time > 600000 && time <= 720000) {
      return 'warning';
    } else {
      return 'alert';
    }
  }

}
