import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChange, SimpleChanges } from '@angular/core';
import { FlowData, OrderCardData, OrderLine } from '@models/orderCardData.model';
import { StateCard } from '@models/stateCard.model';
import { OrdersStateService, UtilsService } from '@services';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';
import { Constants } from '@share/constants';
import * as moment from 'moment';
import { ConfigData } from '@models/config.model';

@Component({
  selector: 'app-order-pack',
  templateUrl: './order-pack.component.html',
  styleUrls: ['./order-pack.component.scss'],
})
export class OrderPackComponent {
  @ViewChild('orderCard') card: ElementRef;
  @Input() configuration: ConfigData;
  @Input() orderOriginal: OrderCardData;
  @Input() orderCardSet: OrderCardData;
  @Input() header: boolean = true;
  @Input() footer: boolean = true;
  @Input() updateCard: boolean = true;
  @Input() hasCamera: boolean = false;
  @Output() clickedItem: EventEmitter<{order: OrderCardData, item: OrderLine}> = new EventEmitter();
  @Output() pressedItem: EventEmitter<{order: OrderCardData, item: OrderLine}> = new EventEmitter();
  @Output() clickedOrder: EventEmitter<{order: OrderCardData, action: string}> = new EventEmitter();
  @Output() closeOrder: EventEmitter<{order: OrderCardData, action: string}> = new EventEmitter();
  @Output() captureWebCamPhoto: EventEmitter<{order: OrderCardData, action: string}> = new EventEmitter();
  @Output() orderTimeState: EventEmitter<{order: OrderCardData, timeState: number}> = new EventEmitter();

  checksAnimation = 0;
  checkOrderAnimation = '';

  dataCard: StateCard = {};

  riderTime: string;
  riderMinutes: any;

  setHeaderStatus: any;

  constructor(
    public translate: TranslateService,
    public utilsService: UtilsService,
    private ordersStateService: OrdersStateService,
  ){}

  ngOnInit(){
    this.orderState();
  }

  ngOnChanges(changes:SimpleChanges) {
    if(this.checkOrderToRefresh(changes) || !!changes.updateCard){     
      this.orderState();      
    }
  }

  ngOnDestroy() {
    if(this.setHeaderStatus) clearInterval(this.setHeaderStatus);
  }

  checkDisableButton(state: string){
    switch (state) {
      case 'photo':
        return (this.checkStatusItems()) ? false : true;
      case 'finish':
        if(
          (this.configuration.free_pack && !!this.orderOriginal.photo_url) || 
          (this.configuration.free_pack && !this.hasCamera) ||
          (!this.configuration.free_pack && this.checkStatusItems() && !this.hasCamera) ||
          (!this.configuration.free_pack && this.checkStatusItems() && !!this.orderOriginal.photo_url)
        ) return false;
        return true;

      case 'start':
      default:
        return (this.checkStatusItems() || this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.orderOriginal.id));
    }
  }

  trackByItems(item: any ): string {
    return item;
  }

  checkRiderStimation(milliseconds: number){
    let timeLeft = (this.orderCardSet?.rider_arriving_at) ? moment(this.orderCardSet?.rider_arriving_at).valueOf() - milliseconds : undefined;
    if(!timeLeft || this.orderCardSet?.rider_arrived) return undefined;
    let minutes = (!!timeLeft && timeLeft > 0) ? moment.duration(timeLeft).minutes() : 1;
    return (minutes > 1) ? minutes : 1;
  }

  checkOrderToRefresh(changes: SimpleChanges){
    if(!changes.orderOriginal || !changes.orderOriginal.previousValue || !changes.orderOriginal.currentValue) return false;
    const result = _.pickBy(changes.orderOriginal.currentValue, (v, k) => !_.isEqual(changes.orderOriginal.previousValue[k], v))    
    return !_.isEmpty(result);
  }

  addNewImage(){
    this.captureWebCamPhoto.emit({order: this.orderOriginal, action: 'add'});
  }

  clickInOrder(){
    if(this.orderOriginal.dates.cancelled_at) {
      this.ordersStateService.clickCancelledOrder(this.orderOriginal);
      return;
    }
    this.clickedOrder.emit({order: this.orderOriginal, action: this.dataCard.flowStatus});
    if(!this.orderOriginal.dates.timestamp_1)this.triggerStatusAnimation(this.orderOriginal);

  }

  clickCloseOrder(){
    this.closeOrder.emit({order: this.orderOriginal, action: this.dataCard.flowStatus});
    if(!this.orderOriginal.dates.timestamp_1)this.triggerStatusAnimation(this.orderOriginal);
  }

  orderState(){

    const milliseconds: number = this.utilsService.getTimeStamp();

    this.riderMinutes = this.checkRiderStimation(milliseconds);
    this.riderTime = this.ordersStateService.checkRiderTime(this.orderCardSet);

    this.dataCard.classHeader = '';
    this.dataCard.isGrid = true;
    if (!this.header) this.dataCard.classHeader += 'card--break-top';
    if (!this.footer) this.dataCard.classHeader += ' card--break-bottom';
    this.dataCard = this.ordersStateService.checkOrderState(this.orderOriginal, this.dataCard, this.translate.instant('card.finish'));

    if(this.orderCardSet?.order_lines.every(orderLine => this.checkFlowItems(orderLine)) && !this.orderOriginal.is_started){
      this.clickedOrder.emit({order: this.orderOriginal, action: this.dataCard.flowStatus});
    }

  }

  clickItem(item: OrderLine){
    if(!this.orderOriginal.is_started) return;
    this.clickedItem.emit({order: this.orderOriginal, item: item})    
    this.triggerAnimation(item.item_number);
  }

  longPress(item){
    if(!this.orderOriginal.is_started) return;
    this.pressedItem.emit({order: this.orderOriginal, item: item})
  }

  checkWarning(item){    
    return item.product.flow.length && item.product.flow[0].order === 1;
  }

  checkFlowItems(item: OrderLine) {
    let canBeChecked: boolean = false;

    if(item?.qty === item?.qty_checks) return false;

    if(item?.is_combo){
      canBeChecked = !!this.orderOriginal?.all_flows.find( flow => 
        !flow.is_combo
        && (flow.name !== this.orderOriginal?.station_type && flow.name !== Constants.STATION_TYPE_PREP)
        && !flow.finished_at 
        && item?.item_number === flow?.parent_item_number)
        
    }
    else if(item?.is_modifier){
      canBeChecked = !!this.orderOriginal?.all_flows.find( flow => 
        (flow.name !== this.orderOriginal?.station_type && flow.name !== Constants.STATION_TYPE_PREP) && !flow.finished_at && item?.parent_item_number === flow?.parent_item_number)
    }
    else{
      canBeChecked = !!this.orderOriginal?.all_flows.find( flow => 
        (flow.name !== this.orderOriginal?.station_type && flow.name !== Constants.STATION_TYPE_PREP) && !flow.finished_at && 
        (item?.item_number === flow?.parent_item_number || item?.item_number === flow?.item_number))
    }

    return !canBeChecked;
  }

  checkStatusItems(){
    if(!this.orderOriginal.is_started || this.orderOriginal.dates.cancelled_at) return false;
    let canBeClosed: OrderLine;
    canBeClosed = this.orderOriginal.order_lines.find(orderLine => !orderLine?.qty_checks || orderLine?.qty_checks < orderLine.qty);
    return !canBeClosed;
  }

  logOrder(){
    this.ordersStateService.logOrder(this.orderOriginal);    
  }

  triggerAnimation(item_number: number) {
    this.checksAnimation = item_number;
    setTimeout(() => { this.checksAnimation = 0; }, 350);
  }

  triggerStatusAnimation(order: OrderCardData) {
    this.checkOrderAnimation = order.id;
    setTimeout(() => { this.checkOrderAnimation = ''; }, 350);
  }
}
