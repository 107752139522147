import { BatchOrdersComponent } from "./batch-orders/batch-orders.component";
export { BatchOrdersComponent } from "./batch-orders/batch-orders.component";

import { CalculatorHdComponent } from "./calculator-hd/calculator-hd.component";
export { CalculatorHdComponent } from "./calculator-hd/calculator-hd.component";

import { ChecklistItemsComponent } from "./checklist-items/checklist-items.component";
export { ChecklistItemsComponent } from "./checklist-items/checklist-items.component";

import { DashboardComponent } from "./dashboard/dashboard.component";
export { DashboardComponent } from "./dashboard/dashboard.component";

import { HorizontalScrollComponent } from "./horizontal-scroll/horizontal-scroll.component";
export { HorizontalScrollComponent } from "./horizontal-scroll/horizontal-scroll.component";

import { MenuComponent } from "./menu/menu.component";
export { MenuComponent } from "./menu/menu.component";

import { NetDisabledComponent } from "./net-disabled/net-disabled.component";
export { NetDisabledComponent } from "./net-disabled/net-disabled.component";

import { OrderCookComponent } from "./order-cook/order-cook.component";
export { OrderCookComponent } from "./order-cook/order-cook.component";

import { OrderHighDemandCookComponent } from "./order-high-demand-cook/order-high-demand-cook.component";
export { OrderHighDemandCookComponent } from "./order-high-demand-cook/order-high-demand-cook.component";

import { OrderListCookComponent } from "./order-list-cook/order-list-cook.component";
export { OrderListCookComponent } from "./order-list-cook/order-list-cook.component";

import { OrderListPackComponent } from "./order-list-pack/order-list-pack.component";
export { OrderListPackComponent } from "./order-list-pack/order-list-pack.component";

import { OrderListPrepComponent } from "./order-list-prep/order-list-prep.component";
export { OrderListPrepComponent } from "./order-list-prep/order-list-prep.component";

import { OrderPackComponent } from "./order-pack/order-pack.component";
export { OrderPackComponent } from "./order-pack/order-pack.component";

import { OrderPrepComponent } from "./order-prep/order-prep.component";
export { OrderPrepComponent } from "./order-prep/order-prep.component";

import { OrderRunnerComponent } from "./order-runner/order-runner.component";
export { OrderRunnerComponent } from "./order-runner/order-runner.component";

import { OrderSiteComponent } from "./order-site/order-site.component";
export { OrderSiteComponent } from "./order-site/order-site.component";

import { OrderLiteComponent } from "./order-lite/order-lite.component";
export { OrderLiteComponent } from "./order-lite/order-lite.component";

import { PeopleSelectComponent } from "./people-select/people-select.component";
export { PeopleSelectComponent } from "./people-select/people-select.component";

import { SaleItemsRelatedComponent } from "./sale-items-related/sale-items-related.component";
export { SaleItemsRelatedComponent } from "./sale-items-related/sale-items-related.component";

import { SitesSelectComponent } from "./sites-select/sites-select.component";
export { SitesSelectComponent } from "./sites-select/sites-select.component";

import { VideoFrameComponent } from "./video-frame/video-frame.component";
export { VideoFrameComponent } from "./video-frame/video-frame.component";

export const Components: any[] = [
    BatchOrdersComponent,
    CalculatorHdComponent,
    ChecklistItemsComponent,
    DashboardComponent,
    HorizontalScrollComponent,
    MenuComponent,
    OrderCookComponent,
    OrderHighDemandCookComponent,
    OrderListCookComponent,
    OrderListPackComponent,
    OrderListPrepComponent,
    OrderLiteComponent,
    OrderPackComponent,
    OrderPrepComponent,
    OrderRunnerComponent,
    OrderSiteComponent,
    PeopleSelectComponent,
    NetDisabledComponent,
    SaleItemsRelatedComponent,
    SitesSelectComponent,
    VideoFrameComponent
];