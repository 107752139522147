import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerTimeModel } from '@models/serverTime.model';
import { filter, map, skip } from 'rxjs/operators';
import { Constants } from '@share/constants';
import { Order } from '@models/orderFirebase.model';
import { PeopleSelectComponent, SitesSelectComponent } from '@share/components';
import { FirebasePeople } from '@models/people.model';
import { AppState } from 'src/app/core/app.reducer';
import { Store } from '@ngrx/store';
import * as stationRestaurantsActions from '../../features/station-brands/store/station-brands.actions';
import { StationBrandsService } from './station-brands.service';
import { StrikedItem } from '@models/stateCard.model';

export interface waitingFirebaseResponseModel { 
  orderId: string, 
  processing: boolean 
}


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  eventsTimer: number = 0;
  eventsTimer$: Subscription;

  isLoading: boolean = false;
  isAlertEnable: boolean = true;
  isModalActive: boolean = false;

  isPeopleModalActive: boolean = false;
  isSiteModalActive: boolean = false;

  initTime$: BehaviorSubject<number> = new BehaviorSubject(null);
  serverTime$: BehaviorSubject<number> = new BehaviorSubject(null);

  openStoresStatusTimer$: Subscription;

  peopleTimer$: Subscription;

  netDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  waitingFirestoreResponse$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  currentOrdersUpdated$: BehaviorSubject<Order[]> = new BehaviorSubject([]);

  strikedItems$: BehaviorSubject<StrikedItem[]> = new BehaviorSubject([]);

  constructor(
    private translate: TranslateService,
    public loadingController: LoadingController, 
    private alertController: AlertController,
    private modalController: ModalController,
    private store: Store<AppState>,
    private stationBrandsService: StationBrandsService,
    private toastr: ToastrService,
    private http: HttpClient,
    ) {
      this.pollingServerTime();
    }

  async showToast(type, code){
    switch (type) {
      case 'success-scan':
        this.toastr.success(null, code,
        {
          timeOut: 6000,
          positionClass: 'toast-bottom-right'
        });
        this.playSound(type);
        break;

      case 'error-scan':
        this.toastr.error(null, code,
        {
          timeOut: 6000,
          positionClass: 'toast-bottom-right'
        });
        this.playSound(type);
        break;

      case 'order-closed-success':
        this.toastr.success(null, code,
        {
          timeOut: 6000,
          positionClass: 'toast-bottom-right'
        });
        break;

      case 'order-closed-warning':
        this.toastr.warning(null, code,
        {
          timeOut: 6000,
          positionClass: 'toast-bottom-right',
          toastClass: 'custom-toast ngx-toastr'
        });
        break;

      case 'order-closed-alert':
        this.toastr.error(null, code,
        {
          timeOut: 6000,
          positionClass: 'toast-bottom-right',
          toastClass: 'custom-toast ngx-toastr'
        });
        break;
    }
  }  

  playSound(sound: string){
    const audio = new Audio(`./../assets/sounds/${sound}.wav`);
    audio.addEventListener("canplaythrough", () => {
      audio.play().catch(e => {
        window.addEventListener('click', () => {
          audio.play()
        }, { once: true })
      })
    });

  }

  async showLoader() {
    this.isLoading = true;
    const loading = await this.loadingController.create({
      spinner: 'crescent',
      // message: 'Please wait...',
      translucent: true,
      cssClass: 'custom-spinner'
    });
    return await loading.present().then(() => {
        if (!this.isLoading) {
          loading.dismiss().then(() => console.log('abort presenting'));
        }
      });
  }

  async dismissLoader() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then((response) => {
      console.log('Loader closed!', response);
    }).catch((err) => {
      console.log('Error occured : ', err);
    });
  }

  async showLoginAlert(message: string) {
    const alert = await this.alertController.create({
      header: 'Opps',
      subHeader: message,
      cssClass: 'custom-alert',
      buttons: [{
        text: 'OK',
        cssClass: 'button button--primary'
      }]
    });

    await alert.present();
  }

  async showAlert(title: string, subtitle: string, buttons: string[]) {
    const alert = await this.alertController.create({
      header: title,
      subHeader: subtitle,
      cssClass: 'custom-alert',
      // buttons: buttons
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'btn-secondary'
        },
        {
          text: 'OK',
          role: 'ok'
        }
      ]
    });

    await alert.present();

    return alert.onDidDismiss()
  }

  async showNetAlert() {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      backdropDismiss: false,
      header: 'Opps',
      subHeader: 'No hay conexión a internet'
    });

    await alert.present();
  }

  pollingServerTime() {
    timer(0, Constants.POLLING_SERVER_TIME).pipe(skip(1)).subscribe({
      next: () => {
        let getServerTime$: Subscription = this.getServerTime()
          .subscribe({ 
            next: (data: ServerTimeModel)=> {
              this.serverTime$.next(moment(data?.timestamp).valueOf());
              this.initTime$.next(moment().valueOf());
            },
            error: ()=>{
              getServerTime$.unsubscribe();
            },
            complete: () =>{
              getServerTime$.unsubscribe();
            }
          })
      }
    });
  }

  peopleTimer(people: FirebasePeople){
    if(this.peopleTimer$) this.peopleTimer$.unsubscribe();
    this.peopleTimer$ = timer(0, Constants.POLLING_USER_TIME).pipe(skip(1)).subscribe(() => {
      if(this.isModalActive){
        this.peopleTimer$.unsubscribe();
        return;
      }

      if(!this.isModalActive && people && moment().isAfter(moment(people.endTime))){
        this.showPeopleSelectModal();
      }
    });
  }

  public getServerTime(): Observable<ServerTimeModel>{
    const url: string = `${environment.server_time}`;
    return this.http.get(url) as Observable<ServerTimeModel>;
  }

  public getTimeStamp(): number{
    const diffTime: number = moment().valueOf() - this.initTime$.value;
    const nowTime = this.serverTime$.value + diffTime;
    return nowTime
  }

  public getRandomMessage(type:string){
    let randomNumb = Math.floor(Math.random()*(4)+1);
    return this.translate.instant(`gamification.messages.${type}_${randomNumb}`);
  }

  public setEventsTimer(){
    if(this.eventsTimer$) this.eventsTimer$.unsubscribe();
    this.eventsTimer = 0;
    this.showPeopleSelectModal();
  }

  async showPeopleSelectModal(){
    if(this.isPeopleModalActive) return;
    this.isModalActive = true;
    this.isPeopleModalActive = true;

    if(this.eventsTimer$) this.eventsTimer$.unsubscribe();
    if(this.peopleTimer$) this.peopleTimer$.unsubscribe();;

    const modal =  await this.modalController.create({
      component: PeopleSelectComponent,
      cssClass: 'custom-modal',
      backdropDismiss: false
    });

    await modal.present();

    modal.onDidDismiss().then(() => {
      this.isModalActive = false;
      this.isPeopleModalActive = false;
      this.eventsTimer$ = timer(0, 1000).subscribe({
        next: () => {
          this.eventsTimer++;
          if(this.eventsTimer >= Constants.EVENTS_TIMER && !this.isModalActive && !this.netDisabled$.value && this.isAlertEnable && !!localStorage.getItem('user')){
            this.eventsTimer = 0;
            this.showPeopleSelectModal();
          }
        },
        complete: () => {
          this.eventsTimer$.unsubscribe();
        }
      })
    })
  }

  async showSitesModal(){
    if(this.isSiteModalActive) return;
    this.isSiteModalActive = true;
    const modal =  await this.modalController.create({
      component: SitesSelectComponent,
      cssClass: 'custom-modal',
      backdropDismiss: false
    });

    await modal.present();

    modal.onDidDismiss().then(() => {
      this.isSiteModalActive = false;
      const delayPolling$ = timer(500).subscribe({

        next: () => {
          this.stationBrandsService.pollingStationBrands();
        },
        complete: () => {
          delayPolling$.unsubscribe();
        }
      })

      // this.stationBrandsService.pollingStationBrands();
    })
  }

  updateStrikedItems(strikedItem: StrikedItem){
    const strikedItems = this.strikedItems$.value;
    const index = strikedItems.findIndex(item => item.item_id === strikedItem.item_id && item.item_modifiers.length === strikedItem.item_modifiers.length && item.item_modifiers.every((value) => value === strikedItem.item_modifiers.find(modifier => modifier === value)));
    if(index !== -1){
      strikedItems.splice(index, 1);
    } else {
      strikedItems.push(strikedItem);
    }
    this.strikedItems$.next(strikedItems);
  }
}
