/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable ,inject} from '@angular/core';
import { Order } from '@models/orderFirebase.model';
import { combineLatest, Observable, of, from } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';

import { ConfigData, FirebasePeople, FirebaseStats, OrderCardData, OrderLine, UserData } from '@models';
import { AngularFireStorage } from '@angular/fire/compat/storage';


import { UtilsService } from './utils.service';
import * as _ from 'lodash';
import { Constants } from '@share/constants';
import { batchItem } from '@models/batchSet.model';
import { SetCard } from '@models/card.model';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {



  initTime: number;
  serverTime: number;



  constructor(private readonly firestore: AngularFirestore, private utilsService: UtilsService, private storage: AngularFireStorage) { }

  getOrders(user: UserData, config: ConfigData): Observable<Order[]> {

    let firebaseQuery;

    // if (user.type === 'site' || user.type === 'runner' || user.type === 'lite' || !user.stationRestaurants || user?.stationRestaurants.length === 0) {
    if (user.type === Constants.STATION_TYPE_SITE || user.type === Constants.STATION_TYPE_RUNNER || !user.stationRestaurants || user?.stationRestaurants.length === 0) {
      firebaseQuery = combineLatest([this.firestore.collection('orders', (ref) =>
        ref
          .where('restaurant.site', 'in', [user.site.short_name])
          .where('sitting', '==', config.sitting)
      ).stateChanges(['modified', 'added', 'removed']) as Observable<any>
      ]);
    } else {
      const restaurantsOption = this.checkRestaurantsOptions(user);
      firebaseQuery = combineLatest(restaurantsOption.map((restaurants) =>
        this.firestore.collection('orders', (ref) =>
          ref
            .where('restaurant.id', 'in', restaurants)
            .where('sitting', '==', config.sitting)
        ).stateChanges(['modified', 'added', 'removed']) as Observable<any>
      ));
    }

    const firestoreOrders = firebaseQuery.pipe(
      map((arrDocs: any[]) => {
        const docsData = [];
        arrDocs.forEach(docs => {
          docs.map((a: DocumentChangeAction<Order>) => {
            const doc = a.payload.doc.data();
            if (doc.status !== 6 && ((user.type === Constants.STATION_TYPE_SITE || user.type === Constants.STATION_TYPE_LITE) ||
              ((user.type === Constants.STATION_TYPE_HIGH_DEMAND || user.type === Constants.STATION_TYPE_COOK || user.type === Constants.STATION_TYPE_PREP) && doc.status <= 2) ||
              (user.type === Constants.STATION_TYPE_PACK && doc.status <= 3) ||
              (user.type === Constants.STATION_TYPE_RUNNER && doc.status <= 2))
            ) {
              doc.change_type = a.type;
              docsData.push(doc);
            }
          });
        });
        return _.uniqBy(docsData, 'id');
      }),
      distinctUntilChanged((prev: any[], curr: any[]) => {
        const xorWithResult = _.xorWith(prev, curr, _.isEqual);
        const emptyResult = _.isEmpty(xorWithResult);
        this.utilsService.currentOrdersUpdated$.next(curr);

        return emptyResult;
      })
    );

    return firestoreOrders;
  }

  checkRestaurantsOptions(user: UserData) {
    const restaurantsId: string[] = _.map(user.stationRestaurants, 'external_id');
    return _.chunk(restaurantsId, 10);
  }

  updateStatus(order: OrderCardData, people: FirebasePeople, user: UserData): Observable<Promise<void>> {
    const status: number = order.status + 1;

    const settedProccessingOrders: any[] = this.utilsService.waitingFirestoreResponse$.value;
    settedProccessingOrders.push(order.id);
    this.utilsService.waitingFirestoreResponse$.next(settedProccessingOrders);

    const objUpdate: any = {};

    objUpdate.kds_source = 'KISS';
    objUpdate.own_image = order.own_image || false;
    if(order.photo_url) objUpdate.photo_url = order.photo_url;
    objUpdate.status = status;
    objUpdate[`dates.timestamp_${status}`] = new Date(this.utilsService.getTimeStamp()).toISOString();
    objUpdate[`cook_info.status_${status}`] = {
      name: people?.name ?? 'unknown',
      id: people?.employeeId ?? 0,
      station_id: user.id || 0,
      station_name: user.name || 'station unknown'
    };

    const resultStatus = of(this.firestore.collection('orders').doc(order.id).update(objUpdate));
    return resultStatus;
  }

  updatePickerQr(order: OrderCardData, people: FirebasePeople, user: UserData): Observable<Promise<void>> {
    const status = 3;
    const nowTimestamp = new Date(this.utilsService.getTimeStamp()).toISOString();

    const settedProccessingOrders: any[] = this.utilsService.waitingFirestoreResponse$.value;
    settedProccessingOrders.push(order.id);
    this.utilsService.waitingFirestoreResponse$.next(settedProccessingOrders);

    const objUpdate: any = {};

    objUpdate.kds_source = 'KISS';
    objUpdate.status = status;
    if (order.status === 1) { objUpdate[`dates.timestamp_2`] = nowTimestamp; }
    objUpdate[`dates.timestamp_${status}`] = nowTimestamp;
    objUpdate.scanned_dispatch = true;
    objUpdate[`cook_info.status_${status}`] = {
      name: people?.name ?? 'unknown',
      id: people?.employeeId ?? 0,
      station_id: user.id || 0,
      station_name: user.name || 'station unknown'
    };

    const resultStatus = of(this.firestore.collection('orders').doc(order.id).update(objUpdate));
    return resultStatus;
  }

  updateOrderFlow(order: OrderCardData, action: string, user: UserData, people: FirebasePeople): Observable<Promise<void>> {

    const objUpdate: any = {};
    const nowTimestamp = new Date(this.utilsService.getTimeStamp()).toISOString();

    const settedProccessingOrders: any[] = this.utilsService.waitingFirestoreResponse$.value;
    settedProccessingOrders.push(order.id);
    this.utilsService.waitingFirestoreResponse$.next(settedProccessingOrders);

    const updateStatus = this.checkOrderStatus(order, action, user.type);

    objUpdate.status = updateStatus;

    const responsible = {
      name: people.name,
      id: people.employeeId,
      station_id: user.id,
      station_name: user.name
    };

    objUpdate[`cook_info.status_${updateStatus}`] = responsible;

    if (updateStatus !== order.status) { objUpdate[`dates.timestamp_${updateStatus}`] = nowTimestamp; }

    order.order_lines.forEach(orderLine => {
      let propAction: string;
      let propPeople: string;
      switch (true) {
        case orderLine.product.flow.length > 0 && !orderLine.is_modifier && ((action === 'started_at' && !orderLine.product.flow[0].started_at) || action === 'finished_at'):
          propAction = `order_lines.${orderLine.internal_id}.product.flow.${orderLine.product.flow[0].internal_id}.${action}`;
          propPeople = `order_lines.${orderLine.internal_id}.product.flow.${orderLine.product.flow[0].internal_id}.cook_by`;
          objUpdate[propAction] = nowTimestamp;
          objUpdate[propPeople] = responsible;
          break;

        case orderLine.product.flow.length > 0 && orderLine.is_modifier && ((action === 'started_at' && !orderLine.product.flow[0].started_at) || action === 'finished_at'):
          propAction = `order_lines.item_${orderLine.parent_item_number}.modifiers.${orderLine.internal_id}.product.flow.${orderLine.product.flow[0].internal_id}.${action}`;
          propPeople = `order_lines.item_${orderLine.parent_item_number}.modifiers.${orderLine.internal_id}.product.flow.${orderLine.product.flow[0].internal_id}.cook_by`;
          objUpdate[propAction] = nowTimestamp;
          objUpdate[propPeople] = responsible;
          break;
      }
    });

    objUpdate.kds_source = 'KISS';
    objUpdate.own_image = order.own_image || false;
    if(order.photo_url) objUpdate.photo_url = order.photo_url;

    // if (action === 'finished_at') {
    //   const nowTime: number = this.utilsService.getTimeStamp();
    //   const difference: number = (order?.finish_time_estimated - nowTime) / 1000;

    //   let typeToast: string; let messageToast: string;


    //   switch (true) {
    //     case difference > Constants.WARNING_STATUS_TIME:
    //       typeToast = 'order-closed-success';
    //       messageToast = this.utilsService.getRandomMessage('success');
    //       break;
    //     case difference > 0 && difference < Constants.WARNING_STATUS_TIME:
    //       typeToast = 'order-closed-warning';
    //       messageToast = this.utilsService.getRandomMessage('warning');
    //       break;
    //     default:
    //       typeToast = 'order-closed-alert';
    //       messageToast = this.utilsService.getRandomMessage('alert');
    //       break;
    //   }


    //   // this.utilsService.showBonusModal(order);
    //   this.utilsService.showToast(typeToast, messageToast);
    // }

    return of(this.firestore.collection('orders').doc(order.id).update(objUpdate));
  }

  addBatchItem(batchItem: batchItem, action: string, user: UserData, people: FirebasePeople): Observable<Promise<void>> {

    const batch = this.firestore.firestore.batch();

    const responsible = {
      name: people.name,
      id: people.employeeId,
      station_id: user.id,
      station_name: user.name
    };

    const docsToUpdate = [];

    batchItem.item_orders.forEach(item_order => {

          const objUpdate: any = {};
          const nowTimestamp = new Date(this.utilsService.getTimeStamp()).toISOString();
          let prop_action_step_1: string;
          let prop_action_step_2: string;
          let prop_people_step_1: string;
          let prop_people_step_2: string;

          prop_action_step_1 = `order_lines.${item_order.order_line.internal_id}.product.flow.step_1.${action}`;
          prop_action_step_2 = `order_lines.${item_order.order_line.internal_id}.product.flow.step_2.${action}`;
          prop_people_step_1 = `order_lines.${item_order.order_line.internal_id}.product.flow.step_1.cook_by`;
          prop_people_step_2 = `order_lines.${item_order.order_line.internal_id}.product.flow.step_2.cook_by`;

          objUpdate[prop_action_step_1] = nowTimestamp;
          objUpdate[prop_action_step_2] = nowTimestamp;
          objUpdate[prop_people_step_1] = responsible;
          objUpdate[prop_people_step_2] = responsible;
          objUpdate.kds_source = 'KISS';


          if(item_order.order_line.modifiers.length > 0){
          
            item_order.order_line.modifiers.forEach(modifier => {
              const prop_action_step_1_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_1.${action}`;
              const prop_action_step_2_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_2.${action}`;
              const prop_people_step_1_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_1.cook_by`;
              const prop_people_step_2_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_2.cook_by`;
              objUpdate[prop_action_step_1_modifier] = nowTimestamp;
              objUpdate[prop_action_step_2_modifier] = nowTimestamp;
              objUpdate[prop_people_step_1_modifier] = responsible;
              objUpdate[prop_people_step_2_modifier] = responsible;
            });

          }

          if(action === 'started_at' && item_order.order.status === 0){
            objUpdate.status = 1;
            objUpdate[`dates.timestamp_1`] = nowTimestamp;
          }


          const orderIndex = docsToUpdate.findIndex(doc => doc.id === item_order.order.id);
          if (orderIndex === -1) {
            docsToUpdate.push({ id: item_order.order.id, data: objUpdate });
          } else {
            docsToUpdate[orderIndex].data[prop_action_step_1] = nowTimestamp;
            docsToUpdate[orderIndex].data[prop_action_step_2] = nowTimestamp;
            docsToUpdate[orderIndex].data[prop_people_step_1] = responsible;
            docsToUpdate[orderIndex].data[prop_people_step_2] = responsible;

            if(item_order.order_line.modifiers.length > 0){
              item_order.order_line.modifiers.forEach(modifier => {
                const prop_action_step_1_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_1.${action}`;
                const prop_action_step_2_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_2.${action}`;
                const prop_people_step_1_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_1.cook_by`;
                const prop_people_step_2_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_2.cook_by`;
                docsToUpdate[orderIndex].data[prop_action_step_1_modifier] = nowTimestamp;
                docsToUpdate[orderIndex].data[prop_action_step_2_modifier] = nowTimestamp;
                docsToUpdate[orderIndex].data[prop_people_step_1_modifier] = responsible;
                docsToUpdate[orderIndex].data[prop_people_step_2_modifier] = responsible;
              });
            
            }
          }
        });
  
    docsToUpdate.forEach(doc => {
      const docRef = this.firestore.firestore.collection('orders').doc(doc.id);
      batch.update(docRef, doc.data);
    });

    return of(batch.commit());
  }

  updateOrderPhoto(order: OrderCardData): Observable<Promise<void>> {
    const objUpdate: any = {};
    objUpdate.photo_url = order.photo_url;
    objUpdate.own_image = true;
    if(order.photos_extra) objUpdate.photos_extra = order.photos_extra;
    const resultStatus = of(this.firestore.collection('orders').doc(order.id).update(objUpdate));
    return resultStatus;
  }

  updateBatch(batchesSet: SetCard, action: string, user: UserData, people: FirebasePeople): Observable<Promise<void>> {
    const batch = this.firestore.firestore.batch();
    const responsible = {
      name: people.name,
      id: people.employeeId,
      station_id: user.id,
      station_name: user.name
    };

    const docsToUpdate = [];

    batchesSet.batches.forEach(batch => {
      batch.items.forEach(item => {
        item.item_orders.forEach(item_order => {

          console.log('item_order', item_order);
          
          const objUpdate: any = {};
          const nowTimestamp = new Date(this.utilsService.getTimeStamp()).toISOString();
          let prop_action_step_1: string;
          let prop_action_step_2: string;
          let prop_people_step_1: string;
          let prop_people_step_2: string;

          prop_action_step_1 = `order_lines.${item_order.order_line.internal_id}.product.flow.step_1.${action}`;
          prop_action_step_2 = `order_lines.${item_order.order_line.internal_id}.product.flow.step_2.${action}`;
          prop_people_step_1 = `order_lines.${item_order.order_line.internal_id}.product.flow.step_1.cook_by`;
          prop_people_step_2 = `order_lines.${item_order.order_line.internal_id}.product.flow.step_2.cook_by`;

          objUpdate[prop_action_step_1] = nowTimestamp;
          objUpdate[prop_action_step_2] = nowTimestamp;
          objUpdate[prop_people_step_1] = responsible;
          objUpdate[prop_people_step_2] = responsible;
          objUpdate.kds_source = 'KISS';

          if(item_order.order_line.modifiers.length > 0){
          
            item_order.order_line.modifiers.forEach(modifier => {
              const prop_action_step_1_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_1.${action}`;
              const prop_action_step_2_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_2.${action}`;
              const prop_people_step_1_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_1.cook_by`;
              const prop_people_step_2_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_2.cook_by`;
              objUpdate[prop_action_step_1_modifier] = nowTimestamp;
              objUpdate[prop_action_step_2_modifier] = nowTimestamp;
              objUpdate[prop_people_step_1_modifier] = responsible;
              objUpdate[prop_people_step_2_modifier] = responsible;
            });

          }

          if(action === 'started_at' && item_order.order.status === 0){
            objUpdate.status = 1;
            objUpdate[`dates.timestamp_1`] = nowTimestamp;
          }


          const orderIndex = docsToUpdate.findIndex(doc => doc.id === item_order.order.id);
          if (orderIndex === -1) {
            docsToUpdate.push({ id: item_order.order.id, data: objUpdate });
          } else {
            docsToUpdate[orderIndex].data[prop_action_step_1] = nowTimestamp;
            docsToUpdate[orderIndex].data[prop_action_step_2] = nowTimestamp;
            docsToUpdate[orderIndex].data[prop_people_step_1] = responsible;
            docsToUpdate[orderIndex].data[prop_people_step_2] = responsible;

            if(item_order.order_line.modifiers.length > 0){
              item_order.order_line.modifiers.forEach(modifier => {
                const prop_action_step_1_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_1.${action}`;
                const prop_action_step_2_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_2.${action}`;
                const prop_people_step_1_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_1.cook_by`;
                const prop_people_step_2_modifier: string = `order_lines.${item_order.order_line.internal_id}.modifiers.${modifier.internal_id}.product.flow.step_2.cook_by`;
                docsToUpdate[orderIndex].data[prop_action_step_1_modifier] = nowTimestamp;
                docsToUpdate[orderIndex].data[prop_action_step_2_modifier] = nowTimestamp;
                docsToUpdate[orderIndex].data[prop_people_step_1_modifier] = responsible;
                docsToUpdate[orderIndex].data[prop_people_step_2_modifier] = responsible;
              });
            
            }
          }
        });
      })
    });

    docsToUpdate.forEach(doc => {
      const docRef = this.firestore.firestore.collection('orders').doc(doc.id);
      batch.update(docRef, doc.data);
    });

    return of(batch.commit());
  };

  updateItemCheck(order: OrderCardData, orderLine: OrderLine, event: string): Observable<Promise<void>> {

    let objUpdate: any = {};

    if (!orderLine?.qty_checks) {
      if (!orderLine?.is_modifier) {
        objUpdate = this.checkAllModifiers(order, orderLine, objUpdate, event, true);
      } else {
        const prop = `order_lines.item_${orderLine.parent_item_number}.modifiers.${orderLine.internal_id}.qty_checks`;
        objUpdate[prop] = (event === 'press') ? orderLine.qty_checks = orderLine?.qty : 1;
      }
    } else if (orderLine?.qty_checks < orderLine?.qty) {
      if (!orderLine?.is_modifier) {
        objUpdate = this.checkAllModifiers(order, orderLine, objUpdate, event, true);
      } else {
        const prop = `order_lines.item_${orderLine.parent_item_number}.modifiers.${orderLine.internal_id}.qty_checks`;
        objUpdate[prop] = (event === 'press') ? orderLine.qty_checks = orderLine?.qty : orderLine.qty_checks + 1;
      }
    } else if (orderLine?.qty_checks === orderLine?.qty) {
      if (!orderLine?.is_modifier) {
        objUpdate = this.checkAllModifiers(order, orderLine, objUpdate, event, false);
      } else {
        const prop = `order_lines.item_${orderLine.parent_item_number}.modifiers.${orderLine.internal_id}.qty_checks`;
        objUpdate[prop] = 0;
      }
    }
    return of(this.firestore.collection('orders').doc(order.id).update(objUpdate));
  }

  checkAllModifiers(order: OrderCardData, item: OrderLine, objUpdate, event: string, addCheck: boolean) {
    const prop = `order_lines.${item.internal_id}.qty_checks`;
    let tempQty: number;

    switch (true) {
      case !item?.qty_checks:
        tempQty = (event === 'press') ? item.qty : 1;
        break;
      case addCheck:
        tempQty = (event === 'press') ? item.qty : item?.qty_checks + 1;
        break;
      default:
        tempQty = 0;
        break;
    }

    objUpdate[prop] = tempQty;

    const modifiers = order.order_lines.filter(orderLine => orderLine.is_modifier && orderLine.parent_item_number === item.item_number);

    modifiers.forEach(modifier => {
      const prop2 = `order_lines.item_${item.item_number}.modifiers.${modifier.internal_id}.qty_checks`;
      if (!modifier?.qty_checks && addCheck) {
        objUpdate[prop2] = (event === 'press') ? modifier.qty : 1;
      } else if (modifier?.qty_checks < modifier?.qty || !addCheck) {
        if (addCheck) {
          objUpdate[prop2] = (event === 'press') ? modifier.qty : modifier?.qty_checks + 1;
        } else {
          objUpdate[prop2] = 0;
        }
      }
    });
    return objUpdate;
  }

  checkOrderStatus(order: OrderCardData, action: string, userType: string) {

    switch (true) {
      case order.status === 0:
        return 1;
      // case userType === 'pack' && action === 'finished_at':
      //   return 2;
      case order.status === 1 && action === 'finished_at':
        const checkTags = order.all_flows.filter(flow =>
          flow.name === order.station_type && flow.name !== 'disp' && !flow.finished_at && !flow.tags.find(tag => order.station_tags.find(stationTag => stationTag?.name === tag)));
        const checkFlows = order.all_flows.filter(flow =>
          flow.name !== order.station_type && flow.name !== 'disp' && !flow.finished_at);
        if (checkFlows.length === 0 && checkTags.length === 0 || userType === 'pack') {
          return 2;
        } else {
          return order.status;
        }
      default:
        return order.status;
    }
  }

  getStats(user: UserData, config: ConfigData): Observable<any> {
    const statsId = `sitting_${user.site.short_name}_${config.sitting}`;
    const firebaseStats = this.firestore.collection('stats').doc(JSON.parse(JSON.stringify(statsId))).valueChanges();

    const settedStats = firebaseStats.pipe(
      map((statsElems: any[]) => {
        if (!statsElems) { return statsElems; }
        const statsData: FirebaseStats = JSON.parse(JSON.stringify({ ...statsElems }));
        statsData.restaurants = Object.entries(statsData.restaurants).map(([key, value]: any) => {
          value.internal_id = key.split('_')[1];
          return value;
        });

        if (user.type === Constants.STATION_TYPE_SITE || user.type === Constants.STATION_TYPE_RUNNER ) {
          return statsData;
        }else {
          let stc_time = 0;
          let prep_time = 0;
          let disp_time = 0;
          let num_orders = 0;
          user.stationRestaurants.forEach(stationRestaurant => {
            statsData.restaurants.forEach((restaurant: any) => {
              if (stationRestaurant.external_id === restaurant.internal_id) {
                if (restaurant.stc_time) { stc_time += restaurant.stc_time; }
                if (restaurant.prep_time) { prep_time += restaurant.prep_time; }
                if (restaurant.disp_time) { disp_time += restaurant.disp_time; }
                if (restaurant.num_orders) { num_orders += restaurant.num_orders; }
              }
            });
          });
          statsData.totals.stc_time = stc_time;
          statsData.totals.prep_time = prep_time;
          statsData.totals.disp_time = disp_time;
          statsData.totals.num_orders = num_orders;
        }
        return statsData;
      })
    );
    return settedStats;
  }

  getConfig(user: UserData): Observable<any> {
    const site: string = user.site.short_name.toLocaleLowerCase();
    const firebaseConfig = this.firestore.collection('configuration').doc(JSON.parse(JSON.stringify(site))).valueChanges();

    const checkConfig = firebaseConfig.pipe(
      distinctUntilChanged((prev: any[], curr: any[]) => JSON.stringify(prev) === JSON.stringify(curr))
    );

    return checkConfig;
  }

  getPeople(user: UserData, config: ConfigData): Observable<any> {
    const site: string = user.site.short_name.toLocaleLowerCase();
    const firebasePeople = this.firestore.collection('people', (ref) => ref
      .where('sitting', '==', config.sitting)
    ).valueChanges();

    const settedPeople = firebasePeople.pipe(
      map((peopleElems: FirebasePeople[]) => {

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        const ghostPerson: FirebasePeople = {
          area: 'Cocina',
          employeeId: 0,
          name: 'Cocinero 1',
          site: user.site.short_name,
          sitting: config.sitting,
          shortName: 'C1',
          startTime: new Date(),
          endTime: tomorrow
        };


        if (!peopleElems) { return [ghostPerson]; }

        let peopleData: any = JSON.parse(JSON.stringify([...peopleElems]));

        peopleData = peopleData.filter((people: any) => people.site.toLowerCase() === site.toLowerCase() && people.name && people.name !== 'undefined');

        // no people in this site. add generic name
        if (peopleData.length <= 0) { return [ghostPerson]; }

        peopleData = _.sortBy(peopleData, 'endTime');
        peopleData = _.uniqBy(_.reverse(peopleData), 'employeeId');
        peopleData = _.sortBy(peopleData, 'name');

        peopleData.forEach((people: any) => {
          if (!people.name){
            people.name = 'Cocinero_'+people.employeeId;
          }

          people.shortName = people?.name?.split(' ')[0].charAt(0)||'C';
          if (people?.name?.split(' ')[1]) {
            people.shortName += people?.name?.split(' ')[1].charAt(0);
          }
        });

        return peopleData;
      }),
      distinctUntilChanged((prev: any[], curr: any[]) => {
        const xorWithResult = _.xorWith(prev, curr, _.isEqual);
        const emptyResult = _.isEmpty(xorWithResult);

        return emptyResult;
      })
    );
    return settedPeople;
  }

  getResponsibleSelected(user: UserData): Observable<any> {
    const firebaseResposible = this.firestore.collection('station_responsible').doc(JSON.stringify(user.id)).valueChanges();
    return firebaseResposible;
  }

  getSites(): Observable<any> {
    const firebaseSites = this.firestore.collection('sites').valueChanges();

    const checkSites = firebaseSites.pipe(
      distinctUntilChanged((prev: any[], curr: any[]) => {
        const xorWithResult = _.xorWith(prev, curr, _.isEqual);
        const emptyResult = _.isEmpty(xorWithResult);

        return emptyResult;
      })
    );

    return checkSites;
  }

  updateResponsibleSelected(station_id: number, responsible: FirebasePeople): Observable<any> {

    return of(this.firestore.collection('station_responsible').doc(JSON.stringify(station_id)).set(responsible));
  }

  clearCacheData() {
    this.firestore.firestore.clearPersistence().then(data => console.log('clearPersistence', data));
  }

  uploadFile(dataUrl: any, fileName: string, folder: string): Observable<any> {
   //upload file to firebase storage
   const storageRef = this.storage.ref(folder);
    const imageRef = storageRef.child(fileName);
    const task = imageRef.putString(dataUrl, 'data_url');
    return from(task);
  }
}
